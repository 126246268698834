var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-parallax',{attrs:{"height":"200","src":"https://cdn.vuetifyjs.com/images/cards/dark-beach.jpg"}},[_c('div',{staticClass:"d-flex flex-column fill-height justify-center align-center text-white"},[_c('h1',{staticClass:"text-h4 font-weight-thin mb-4"},[_vm._v("Health")]),_c('h4',{staticClass:"subheading"},[_vm._v("Showing Service health")])])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('BaseMaterialStatsCard',{attrs:{"color":"primary","icon":"mdi-credit-card","title":"Fairpay website","loading":_vm.wss.loading,"value":_vm.wss.loading ? 'Loading' : 'Normal',"sub-icon":"mdi-clock","sub-text":"Just Updated"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('BaseMaterialStatsCard',{attrs:{"color":"primary","icon":"mdi-credit-card","title":"DataSoap Credits","loading":_vm.health.datasoap.loading,"value":_vm.health.datasoap.loading
            ? 'Loading'
            : _vm.health.datasoap.data.credits.hlr,"sub-icon":"mdi-clock","sub-text":"Just Updated"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('BaseMaterialStatsCard',{attrs:{"color":"primary","icon":"mdi-credit-card","title":"Debounce Credits","loading":_vm.health.debounce.loading,"value":_vm.health.debounce.loading ? 'Loading' : _vm.health.debounce.data.balance,"sub-icon":"mdi-clock","sub-text":"Just Updated"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('BaseMaterialStatsCard',{attrs:{"color":"primary","icon":"mdi-credit-card","title":"Postcoder Credits","loading":_vm.health.postcoder.loading,"value":_vm.health.postcoder.loading
            ? 'Loading'
            : _vm.health.postcoder.data.creditsabovethreshold
            ? 'Above 10000'
            : 'Below 10000',"sub-icon":"mdi-clock","sub-text":"Just Updated"}})],1)],1),(_vm.superadmin)?_c('v-text-field',{attrs:{"clearable":""},model:{value:(_vm.firebase_token),callback:function ($$v) {_vm.firebase_token=$$v},expression:"firebase_token"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }