<template>
  <div>
    <v-parallax
      height="200"
      src="https://cdn.vuetifyjs.com/images/cards/dark-beach.jpg"
    >
      <div
        class="d-flex flex-column fill-height justify-center align-center text-white"
      >
        <h1 class="text-h4 font-weight-thin mb-4">Health</h1>
        <h4 class="subheading">Showing Service health</h4>
      </div>
    </v-parallax>
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <BaseMaterialStatsCard
          color="primary"
          icon="mdi-credit-card"
          title="Fairpay website"
          :loading="wss.loading"
          :value="wss.loading ? 'Loading' : 'Normal'"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <BaseMaterialStatsCard
          color="primary"
          icon="mdi-credit-card"
          title="DataSoap Credits"
          :loading="health.datasoap.loading"
          :value="
            health.datasoap.loading
              ? 'Loading'
              : health.datasoap.data.credits.hlr
          "
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <BaseMaterialStatsCard
          color="primary"
          icon="mdi-credit-card"
          title="Debounce Credits"
          :loading="health.debounce.loading"
          :value="
            health.debounce.loading ? 'Loading' : health.debounce.data.balance
          "
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <BaseMaterialStatsCard
          color="primary"
          icon="mdi-credit-card"
          title="Postcoder Credits"
          :loading="health.postcoder.loading"
          :value="
            health.postcoder.loading
              ? 'Loading'
              : health.postcoder.data.creditsabovethreshold
              ? 'Above 10000'
              : 'Below 10000'
          "
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
    </v-row>

    <v-text-field
      v-if="superadmin"
      v-model="firebase_token"
      clearable
    ></v-text-field>
  </div>
</template>

<script>
import { mapState } from "vuex";

// import BaseMaterialCard from '../components/base/MaterialCard.vue'
//import BaseMaterialChartCard from '../components/base/MaterialChartCard.vue'
import BaseMaterialStatsCard from "../components/base/MaterialStatsCard.vue";

export default {
  name: "DashboardDashboard",
  components: {
    //BaseMaterialCard,
    //BaseMaterialChartCard,
    BaseMaterialStatsCard,
  },
  data() {
    return {
      superadmin: true,
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id",
        },
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Salary",
          value: "salary",
          align: "right",
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right",
        },
        {
          sortable: false,
          text: "City",
          value: "city",
          align: "right",
        },
      ],
      items: [
        {
          id: 1,
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Tunrhout",
          salary: "$35,738",
        },
        {
          id: 2,
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,738",
        },
        {
          id: 3,
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Overland Park",
          salary: "$56,142",
        },
        {
          id: 4,
          name: "Philip Chanley",
          country: "Korea, South",
          city: "Gloucester",
          salary: "$38,735",
        },
        {
          id: 5,
          name: "Doris Greene",
          country: "Malawi",
          city: "Feldkirchen in Kārnten",
          salary: "$63,542",
        },
      ],
      tabs: 0,
      tasks: {
        0: [
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false,
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true,
          },
        ],
        1: [
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  computed: {
    ...mapState(["wss", "firebase_token", "health"]),
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },

  mounted() {
    this.$store.dispatch("fetchWSS");
    this.$store.dispatch("fetchDataSoap");
    this.$store.dispatch("fetchDebounce");
    this.$store.dispatch("fetchPostcoder");
  },
};
</script>
